@import url(https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap);
html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 100px;
  font-family: "Metal Mania", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #232323;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-header {
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header-alt {
  background-color: #232323;
  color: #ffffff;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header-alt-home {
  text-align: center;
  background-color: #232323;
  color: #ffffff;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.h1-large {
  font-size: calc(75px + 2vmin);
  color: #3c3c3c;
}

.h1-light-large {
  font-size: calc(75px + 2vmin);
  color: #ffffff;
}

.h2-dark {
  color: #3c3c3c;
}

.h2-dark-large {
  color: #3c3c3c;
  font-size: 1.25vh;
}

.h2-light-large {
  color: #ffffff;
  font-size: 1.25vh;
}

.App-subheader {
  margin-bottom: 5vh;
}

.App-subheader-alt {
  background-color: #232323;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
}

.App-link {
  color: #61dafb;
}

.App-block {
  display: "flex";
  flex-wrap: row;
  justify-content: space-between;
  margin-bottom: 5vmin;
  width: 75%;
}

.App-icons {
  display: flex;
  flex-wrap: row;
  justify-content: space-between;
}

.App-icons .App-icon-link a {
  text-align: center;
  display: inline-block;
  margin: 10px;
  color: #ffffff;
}

.App-navigation {
  display: "flex";
  flex-wrap: row;
  justify-content: space-between;
  background-color: #3c3c3c;
  color: #ffffff;
}

.App-navigation-link {
  color: #ffffff;
  margin-left: 25px;
  margin-right: 25px;
}

.App-navigation-link:hover {
  color: #6e5494;
  text-decoration: none;
}

.icon-spacer {
  padding-top: 25px;
  padding-bottom: 25px;
}

.homepage-icon {
  color: #ff5964;
}

.homepage-card-header {
  color: #35a7ff;
}

.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link:visited {
  color: #ffffff;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #6e5494;
  text-decoration: none;
}

.inline-list {
  display: inline-block;
  padding: 0;
}

.inline-list > li {
  display: inline;
}

.inline-list > li::after {
  content: " ";
  letter-spacing: 1em;
  background: linear-gradient(
    90deg,
    transparent calc(50% - 0.03125em),
    currentColor 0,
    currentColor calc(50% + 0.03125em),
    transparent 0
  );
}

.jumbotron-light {
  background-color: #fafafa;
}

.dark-logo {
  color: #ffffff;
}

.dark-logo:hover {
  color: #6e5494;
}

.btn-center {
  align-items: center;
}

.footer-sticky {
  align-items: center;
  background-color: #3c3c3c;
  color: white;
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 10vmax;
  z-index: -10;
}

.App-navigation-link-small {
  color: #ffffff;
}

.container-spacer {
  margin-top: 2vh;
}

.App-navigation-link-small:hover {
  color: #6e5494;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .footer-image {
    margin: auto;
    width: 50%;
    padding: 10px;
  }

  .App-header-alt-home {
    text-align: center;
    background-color: #232323;
    color: #ffffff;
    padding-top: 10vh;
    padding-bottom: -10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
}

.footer-image {
  height: 250px;
  /* width: auto; */
  align-items: right;
}

.header-image {
  height: 500px;
  width: auto;
  background-color: #232323;
}

.button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.padded-button {
  margin-left: 5px;
  margin-right: 5px;
}

.align-bottom {
  position: absolute;
  bottom: 0;
}

.image-darken {
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.red {
  color: #ff5964;
}

.white {
  color: #ffffff;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 45%;
  padding-top: 25px;
  height: 0;
}
.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-payment {
  height: 288px;
  width: auto;
}

.p-dark {
  font-family: "Times New Roman", cursive;
  color: #3c3c3c;
  text-align: left;
}
