@import url("https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap");

html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0 0 100px;
  font-family: "Metal Mania", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #232323;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
